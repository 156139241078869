<template>
  <div class="container" fluid="lg">
    <user-header></user-header>
    <div v-if="!isBusy" class="row sticky-top bg-white">
      <div class="d-flex align-items-center justify-content-between w-100 my-3">
        <h5>{{ loanHistory.application.contract_no_ref }}</h5>
        <h6
          class="text-primary text-right font-weight-bold cursor-pointer"
          @click="$bvModal.show('modal_barcode')"
          v-html="$t('view_barcode')"
        ></h6>
      </div>
      <nav-bar v-model="active" :nav_list="nav_list"></nav-bar>

      <div class="col-12">
        <div v-if="active == 1" class="row">
          <div v-if="loanHistory" class="col-12 p-0 m-0">
            <div v-for="(paymentSchedule, index) in paymentScheduleList" :key="index" class="d-flex p-3">
              <div class="pr-3">
                <b-avatar
                  v-if="index == currentPeroid"
                  class="align-middle logo-avatar"
                  :src="image.logo"
                  size="2.75rem"
                  variant="primary"
                >
                </b-avatar>
                <b-avatar
                  v-else
                  class="align-middle"
                  size="2.75rem"
                  :variant="index < currentPeroid ? 'success' : 'secondary'"
                >
                  <i class="ri-check-line ri-lg" :class="index < currentPeroid ? 'text-white' : 'text-secondary'"></i>
                </b-avatar>
                <div v-show="index != paymentScheduleList.length - 1" class="mx-auto vertical-divider"></div>
              </div>
              <div>
                <h6 class="m-0">
                  {{ repaymentPeriod(paymentSchedule.period) }}
                </h6>
                <h6 class="mt-2">
                  {{ $t('repayment_amount') }} : {{ paymentSchedule.pmt_amount | Currency }} {{ $t('bahts') }}
                </h6>
                <div class="small text-light mt-2">
                  {{ $t('repayment_date') }}:
                  {{ paymentSchedule.pmt_date | DateFormatter }}
                </div>
              </div>
            </div>
          </div>
          <div v-else class="col-12">
            <h6 class="mt-5 text-center">
              {{ $t('no_loan_schedule') }}
            </h6>
          </div>
        </div>

        <div v-if="active == 2" class="row py-4 px-2">
          <div v-if="paymentScheduleList.length > 0" class="col-12">
            <div class="row">
              <div class="col-6">
                <h6>{{ $t('loan_size') }}</h6>
              </div>
              <div class="col-6 text-right">
                <h6>
                  {{ loanHistory.principle_amount }}
                  {{ $t('bahts') }}
                </h6>
              </div>
              <div class="col-6">
                <h6>{{ $t('loan_term') }}</h6>
              </div>
              <div class="col-6 text-right">
                <h6>{{ loanHistory.term_period }} {{ $t('months') }}</h6>
              </div>
            </div>
          </div>
          <div v-else class="col-12 mt-5">
            <div class="text-center">{{ $t('no_loan_information') }}</div>
          </div>
        </div>
      </div>
    </div>
    <loading :is-busy="isBusy"></loading>
    <b-modal id="modal_barcode" centered scrollable size="sm" hide-header hide-footer content-class="border-0 w-auto">
      <div class="text-center">
        <div class="p-4">
          <img :src="paymentReferencePhoto" class="w-100" />
          <div style="font-size: 11px" class="mt-4">{{ paymentReferenceNumber }}</div>
        </div>
        <!-- <barcode v-bind:value="paymentReferenceNumber" class="w-100"> </barcode> -->
        <input id="testing-code" type="hidden" :value="paymentReferenceNumber" />
        <div class="bg-white mx-auto">
          <b-button pill class="px-4 my-3" variant="primary" @click="copyToClipboard()">{{
            $t('copy_number')
          }}</b-button>
          <!-- <b-button pill variant="outline-primary" size="sm" class="ml-3" @click="downloadReferenceNumber()">
            <b-icon-download></b-icon-download>
          </b-button> -->
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UserHeader from '@/components/UserHeader.vue'
import NavBar from '@/components/NavBar.vue'
import LoanService from '@/api/service/LoanService'
import Loading from '@/components/Loading.vue'
export default {
  components: { UserHeader, NavBar, Loading },
  data() {
    return {
      active: 1,
      image: {
        logo: require('@/assets/images/logo.png'),
      },
      nav_list: [
        { key: 1, value: 'payment_schedule' },
        { key: 2, value: 'loan_information' },
      ],
      loanHistory: null,
      paymentScheduleList: [],

      isBusy: true,
    }
  },
  computed: {
    currentPeroid() {
      var period = this.paymentScheduleList.length
      for (var i = 0; i < this.paymentScheduleList.length; i++) {
        if (this.paymentScheduleList[i].status === 'unpaid') {
          period = i
          break
        }
      }
      return period
    },
    paymentReferenceNumber() {
      return this.loanHistory ? this.loanHistory.qrcode.string : ''
    },
    paymentReferencePhoto() {
      return this.loanHistory ? this.loanHistory.qrcode.photo : ''
    },
  },
  created() {
    this.getLoanHistoryDetail(this.$route.params.id)
  },
  methods: {
    async getLoanHistoryDetail(id) {
      try {
        this.isBusy = true
        const res = await LoanService.loanHistoryDetail({
          id: id,
        })

        this.loanHistory = res.data.data
        if (this.loanHistory) {
          this.paymentScheduleList = this.loanHistory.schedule
        }

        this.isBusy = false
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    repaymentPeriod(value) {
      if (!value) return ''
      const period = parseInt(value)
      switch (period) {
        case 1:
          return this.$t('first_repayment')
        case 2:
          return this.$t('second_repayment')
        case 3:
          return this.$t('third_repayment')
        case 4:
          return this.$t('fourth_repayment')
        case 5:
          return this.$t('fifth_repayment')
        case 6:
          return this.$t('sixth_repayment')
        case 7:
          return this.$t('seven_repayment')
        case 8:
          return this.$t('eighth_repayment')
        default:
          return period
      }
    },
    copyToClipboard() {
      this.$bvModal.hide('modal_barcode')
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text') // 不是 hidden 才能複製
      testingCodeToCopy.select()

      try {
        var successful = document.execCommand('copy')
        var msg = successful ? 'successful' : 'unsuccessful'
        this.showAlert('ငွေပေးချေမှုရည်ညွှန်းနံပါတ်ကို ကူးယူပြီးပါပြီ')
      } catch (err) {
        // this.showAlert('Oops, unable to copy')
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
  },
}
</script>

<style></style>
